import * as React from "react"
import Layout from '../components/Layout'
import Section from '../components/Section'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

// markup
const IndexPage = () => {
  return (
    <Layout pageTitle="Home Page">
      <Section id="hero" background="light">
        <div className="flex">
          <div className="image-wrapper">
            <StaticImage
              src="../images/52birds-cover.jpg"
              alt="Cover of the book 52 Birds by Richard Weeks"
              placeholder="blurred"
            />
          </div>
          <div className="content-wrapper">
            <p>The story of an eight-year quest to photograph and paint the 52 breeding wood warblers of the United States.</p>
          </div>
        </div>
      </Section>
      <Section id="book" background="dark">
        <div>
          <h2>About 52 Small Birds</h2>
          <p className="two-columns">Wood warblers are among the most sought-after of the spring migrants. The small, colorful birds motivate thousands of birders throughout the world to travel to locations both popular and obscure. 52 Small Birds describes the eight-year quest of a bird artist to photograph and paint the 52 breeding warblers of the United States. Comfortably retired and enjoying his passion as an artist, the author was in his 60s when he discovered the joys and challenges of birding. His desire—perhaps more rightly called a fixation—to document warblers led to 11 trips to eight different states, which are described in narration, journal sketches, photographs, and paintings. This narrative relates how the process of searching for, photographing, and painting birds enhanced and deepened the author’s connection to the natural world.</p>
          <div className="single-column">
            <h3>Buy the book</h3>
            <p>To buy a copy of <em>52 Small Birds,</em> send your name, address and a check for $22 + $2 postage and handling to:</p>
            <p className="address"><strong>Lane County Audubon Society<br />
            P.O. Box 5086<br />
            Eugene, OR 97405</strong></p>
            <p>Please make checks payable to LCAS.</p>
          </div>
        </div>
      </Section>
      <Section id="author" background="light">
        <div>
          <h2>The author</h2>
          <div className="flex">
            <div className="content-wrapper">
              <p>Richard Weeks taught secondary science in California and Oregon for 31 years. Since retiring in 1999, he has pursued his lifelong interest in travel and art.</p>
              <p>His journeys to mostly Latin American destinations have provided a wealth of subject matter for his paintings. He has been painting birds since 2003. Richard and his wife, Susan, live in Eugene, Oregon.</p>
            </div>
            <div className="image-wrapper">
              <StaticImage
                src="../images/richard-weeks-author.jpg"
                alt="Richard Weeks, artist, photographer and author of the book 52 Birds"
                placeholder="blurred"
              />
            </div>
          </div>
        </div>
      </Section>
      <Section id="other-books" background="dark">
        <div>
          <h2>Other books by Richard Weeks</h2>
          <div className="row">
            <Link to="/snakes">
              <StaticImage
                src="../images/snakes-dont-eat-carrots-cover.png"
                alt="Cover of the book Snakes Don't Eat Carrots by Richard Weeks"
                placeholder="blurred"
              />
            </Link>
            <Link to="/mild">
              <StaticImage
                src="../images/mild-cover.png"
                alt="Cover of the book Mild by Richard Weeks"
                placeholder="blurred"
              />
            </Link>
            <Link to="/strangelove">
              <StaticImage
                src="../images/dr-strangeloves-playground-cover.png"
                alt="Cover of the book Dr. Strangelove's Playground by Richard Weeks"
                placeholder="blurred"
              />
            </Link>
          </div>
        </div>
      </Section>
      <Section id="art" background="light">
        <div>
          <p>
            <Link to="/art">See more of Richard's paintings</Link>
          </p>
        </div>
      </Section>
    </Layout>
  )
}

export default IndexPage
